<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <g id="Icon_Upload">
      <path
        id="Path_2754"
        d="m8.79,1.19h1.81c.87,0,1.58.69,1.59,1.56v9.35c0,.87-.72,1.56-1.59,1.56h-1.81"
        style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
      />
    </g>
    <polyline
      points="3.05 4.81 .71 7.42 3.05 10.02"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1=".71"
      y1="7.42"
      x2="8.25"
      y2="7.42"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
